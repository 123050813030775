import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { message, Spin } from 'antd';
import Typography from 'antd/lib/typography';

import { ICaisoLocation } from 'src/domain/caiso/drrs/locations/interface';
import { defaultDateFormat } from 'src/domain/common/dateFormatters';
import { Collapse, Divider, Select } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'src/components/CopyToClipboard';
import './DrrsLocationModal.css';
import { useEffect, useState } from 'react';
import { fetchSitesByEnrollmentId, linkLocationsToSites } from 'src/domain/caiso/drrs/locations';

interface IDrrsLocationModalProps {
    location: ICaisoLocation;
    onClose: Function;
}

export function DrrsLocationModal({ location, onClose }: IDrrsLocationModalProps) {
    function closeModal() {
        onClose();
    }

    return (
        <Modal open destroyOnClose title="Location Details" onCancel={closeModal} footer={null} className="drrs-location-modal">
            <Summary {...location} />
            <DropdownsRow location={location} />
            <AddressCollapse {...location} />
            <LSECollapse {...location} />
            <UDCCollapse {...location} />
        </Modal>
    );
}

function DropdownsRow({ location }: { location: ICaisoLocation }) {
    const [siteNames, setSiteNames] = useState<{ site_id: string; site_name: string }[]>([]);
    const [selectedSite, setSelectedSite] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [linking, setLinking] = useState<boolean>(false);

    useEffect(() => {
        const loadSites = async () => {
            setLoading(true);
            try {
                const sites = await fetchSitesByEnrollmentId(location.san!, {
                    pagination: { pageSize: 50, current: 1 },
                });
                setSiteNames(sites);
            } catch (error) {
                message.error("Failed to fetch sites");
            } finally {
                setLoading(false);
            }
        };

        if (location.san) {
            loadSites();
        }
    }, [location.san]);

    const handleLinkLocationToSite = async (selectedSite: string, location: ICaisoLocation) => {
        if (!selectedSite) return;
        setLinking(true);
        try {
            if (location.id !== undefined) {
                await linkLocationsToSites(location.id, parseInt(selectedSite));
                message.success(`Location ${location.id} successfully linked to site ${selectedSite}`);
            } else {
                message.error("Location ID is undefined");
            }
        } catch (error) {
            message.error("Failed to link location to site");
        } finally {
            setLinking(false);
        }
    };

    useEffect(() => {
        if (selectedSite) {
            handleLinkLocationToSite(selectedSite, location);
        }
    }, [selectedSite, location]);

    return (
        <div className="dropdowns-row">
            <Divider>
                <Typography.Text strong>DRMS Site Link</Typography.Text>
            </Divider>
            <Row gutter={24}>
                <Col span={12}>
                    <Typography.Text className="mandatory-label">DRMS Site Name</Typography.Text>
                    {location.site_id && location.site_name ? (
                        <Select value={location.site_id} disabled>
                            <Select.Option value={location.site_id}>{location.site_name}</Select.Option>
                        </Select>
                    ) : (
                        <Spin spinning={linking}>
                            <Select
                                value={selectedSite}
                                onChange={(value) => setSelectedSite(value)}
                                placeholder="Select DRMS Site"
                                loading={loading}
                            >
                                {siteNames.map((site) => (
                                    <Select.Option key={site.site_id} value={site.site_id}>
                                        {site.site_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Spin>
                    )}
                </Col>
            </Row>
        </div>
    );
}


function UDCCollapse(location: ICaisoLocation) {
    return (
        <CustomCollapse
            title="UDC"
            elementsGroups={[
                [{ label: 'UDC Approved', value: location.udc_approved ? 'Y' : 'N' }],
                [{ label: 'UDC Comments', value: location.udc_comments ?? '' }],
                [{ label: 'UDC Reviewer', value: location.udc_reviewer ?? '' }],
                [{ label: 'UDC Contact Info', value: location.udc_contact_info ?? '' }],
            ]}
        />
    );
}

function LSECollapse(location: ICaisoLocation) {
    return (
        <CustomCollapse
            title="LSE"
            elementsGroups={[
                [{ label: 'LSE Approved', value: location.lse_approved ? 'Y' : 'N' }],
                [{ label: 'LSE Comments', value: location.lse_comments ?? '' }],
                [{ label: 'LSE Reviewer', value: location.lse_reviewer ?? '' }],
                [{ label: 'LSE Contact Info', value: location.lse_contact_info ?? '' }],
            ]}
        />
    );
}

function AddressCollapse(location: ICaisoLocation) {
    return (
        <CustomCollapse
            title="Address"
            elementsGroups={[
                [{ label: 'Address 1', value: location.address }],
                [{ label: 'Address 2', value: location.address_2 }],
                [
                    { label: 'City', value: location.address_city },
                    { label: 'State', value: location.address_state },
                ],
                [
                    { label: 'Zip', value: location.address_zip },
                    { label: 'Type', value: location.address_type },
                ],
            ]}
        />
    );
}

function Summary(location: ICaisoLocation) {
    return (
        <div className="locations-detail-summary">
            <CustomRow elements={[{ label: 'Name', value: location.name ?? '' }]} />
            <CustomRow
                elements={[
                    {
                        label: 'SAN',
                        value: location.san ?? '',
                    },
                    {
                        label: 'Status',
                        value: location.status ?? '',
                    },
                ]}
            />
            <CustomRow
                elements={[
                    {
                        label: 'UDC',
                        value: location.udc ?? '',
                    },
                    {
                        label: 'SUBLAP',
                        value: location.sub_lap ?? '',
                    },
                ]}
            />
            <CustomRow
                elements={[
                    {
                        label: 'Start Date',
                        value: defaultDateFormat(location.start_date, '', 'MM-dd-yyyy'),
                    },
                    {
                        label: 'End Date',
                        value: defaultDateFormat(location.end_date, '', 'MM-dd-yyyy'),
                    },
                ]}
            />
            <CustomRow
                elements={[
                    {
                        label: 'Resource',
                        value: location.resource_id ?? '',
                    },
                ]}
            />
        </div>
    );
}

function CustomCollapse({
    title,
    elementsGroups,
}: {
    title: string;
    elementsGroups: { label: string; value: string; withCopyToClipboard?: boolean }[][];
}) {
    return (
        <Collapse
            defaultActiveKey={['1']}
            accordion
            size="small"
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
            <Collapse.Panel key="1" header={title} forceRender>
                <div className="locations-detail-address">
                    {elementsGroups.map((elements, index) => (
                        <CustomRow key={index} elements={elements} />
                    ))}
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}

function CustomRow({ elements }: { elements: { label: string; value: string; withCopyToClipboard?: boolean }[] }) {
    return (
        <Row gutter={8}>
            {elements.map((element, index) => (
                <Col key={index} span={elements.length > 1 ? 12 : 24}>
                    <Typography.Text strong>{element.label}:&nbsp;</Typography.Text>
                    {element.withCopyToClipboard ? (
                        <CopyToClipboard text={element.value} />
                    ) : (
                        <Typography.Text>{element.value}</Typography.Text>
                    )}
                </Col>
            ))}
        </Row>
    );
}
