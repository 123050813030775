import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Transfer from 'antd/lib/transfer';
import { isNull } from 'lodash';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import { IMeter, ISite } from '../../domain/site/interface';
import { useAllMetersListQuery } from '../../domain/site/queries';

interface IManageMeterModalProps {
    onClose: () => void;
    onOk: (selectedMeters: IMeter[]) => void;
    meters: IMeter[];
    site: ISite | null;
}

export const ManageMeterModal = ({ onClose, onOk, meters: initialMeters, site }: IManageMeterModalProps) => {
    const { notification } = App.useApp();
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [transferOptions, setTransferOptions] = useState<IRecordType[]>([]);

    const {
        data: meters = [],
        error,
        isError,
        isLoading,
    } = useAllMetersListQuery({
        include: 'site,account',
    });

    if (isError) {
        notification.error({ key: 'manage-meter-modal-error', message: error?.message || 'Cannot load meters!' });
    }

    const handleOk = () => {
        const selectedMeters = meters.filter(meter => targetKeys.includes(meter.salesforce_id));

        onOk(selectedMeters);
    };

    const handleCancel = () => {
        onClose();
    };

    const handleChange = (newTargetKeys: string[]) => {
        setTargetKeys(newTargetKeys);
    };

    const filterOption = (inputValue: string, option: IRecordType) => {
        const searchValue = inputValue.toLocaleLowerCase();

        return (
            option.title.toLocaleLowerCase().indexOf(searchValue) > -1 ||
            option.key.toLocaleLowerCase().indexOf(searchValue) > -1
        );
    };

    const renderItem = (item: IRecordType) => {
        return item?.siteName ? `${item.title} (${item.siteName})` : item.title;
    };

    useEffect(() => {
        const sortedInitialMeters = orderBy(initialMeters, ['name'], ['asc']);

        setTransferOptions(meters.map(meter => toTransferOptions(meter, site)));
        setTargetKeys(sortedInitialMeters.map(meter => toTransferOptions(meter, site)).map(option => option.key));
    }, [meters, initialMeters, site]);

    return (
        <Modal
            open
            title="Manage Salesforce Meters"
            destroyOnClose
            onCancel={handleCancel}
            width={800}
            styles={{
                mask: { background: 'rgba(0, 0, 0, 0.1)' }, // to not make background very dark
                footer: { padding: '8px 24px' },
            }}
            footer={[
                <Button key="manage-meter-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="manage-meter-modal-submit" type="primary" onClick={handleOk}>
                    Continue
                </Button>,
            ]}
        >
            <Spin tip="Loading..." spinning={isLoading}>
                <Transfer
                    showSearch
                    style={{ width: '100%' }}
                    listStyle={{ width: '48%', height: 300 }}
                    dataSource={transferOptions}
                    targetKeys={targetKeys}
                    titles={['All Salesforce Meters', 'Linked Meters']}
                    render={renderItem}
                    filterOption={filterOption}
                    onChange={handleChange}
                    pagination
                />
            </Spin>
        </Modal>
    );
};

interface IRecordType {
    key: string;
    title: string;
    siteName?: string;
    disabled: boolean;
}

function toTransferOptions(meter: IMeter, site: ISite | null): IRecordType {
    return {
        key: meter.salesforce_id,
        title: meter.name,
        siteName: meter?.site?.site_name,
        disabled: isMeterSelectDisabled(meter, site),
    };
}

function isMeterSelectDisabled(meter: IMeter, site: ISite | null): boolean {
    return !isNull(meter.site_id) && meter.site_id !== site?.site_id;
}
