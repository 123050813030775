import { API } from '../api';
import { IPagination } from '../IPagination';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IAsyncJob, ICreateAsyncJob } from './interface';

export const fetchAsyncJobs = async (
    { sorter, pagination, include, filter }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IAsyncJob>> => {
    const limit = pagination?.pageSize || 10;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;
    if (!sorter?.field || !sorter?.order) {
        sorter = {};
    }

    const path = `/async-job?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...filter,
        ...(include && { include }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of async jobs!`);
};

export const createAsyncJob = async (asyncJob: ICreateAsyncJob): Promise<void> => {
    const response = await API.fetch('/async-job', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(asyncJob),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || 'Cannot create async jobs');
};