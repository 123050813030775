import React from 'react';
import Tag from 'antd/lib/tag';
import { ISite } from '../../domain/site/interface';

const boldTextWrapper = (text: string, isBold?: boolean) => (isBold ? <b>{text}</b> : text);

interface ISiteNameProps {
    site: ISite;
}

export const SiteName: React.FC<ISiteNameProps> = ({ site }) => {
    return (
        <div data-cy='site-name-cell'>
            {boldTextWrapper(site.site_name, site.site_highlight)} {site.closed_at && <Tag color='volcano'>Closed</Tag>}
        </div>
    );
};
