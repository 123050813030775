import InboxOutlined from '@ant-design/icons/InboxOutlined';
import { Col } from 'antd';
import { App, Upload, UploadFile } from 'antd/lib';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import Dragger from 'antd/lib/upload/Dragger';
import orderBy from 'lodash/orderBy';
import React, { useState } from 'react';
import { DebounceSelect } from '../../../components/DebounceSelect/DebounceSelect';
import { checkForNyisoResourceDuplicateByField, getUniqueSansForAutocomplete } from '../../../domain/nyiso';
import {
    ICreateNyisoResource,
    INyisoResource,
    IUpdateNyisoResource,
    NyisoResourceUniqueFields,
} from '../../../domain/nyiso/interface';
import {
    INyisoFormData,
    NyisoCBLMethods,
    NyisoFormData,
    NyisoLoadZones,
    NyisoMeterAuthorities,
    NyisoProvisionalACLOptions,
    NyisoResponseTypeOptions,
    NyisoStates,
} from '../../../domain/nyiso/NyisoFormData';
import { useNyisoResourceCreateMutation, useNyisoResourceUpdateMutation } from '../../../domain/nyiso/queries';
import { PRIMARY_COLOR } from '../../../theme';
import { EnergyDataFileInfo } from './EnergyDataFileInfo';

interface IResourceFormModalProps {
    resource: INyisoResource | Partial<INyisoResource>;
    onClose: Function;
    isEdit: boolean;
}

const ALLOWED_FILE_SIZE_MB = 50;
const ALLOWED_FILE_TYPE = '.csv';

export const ResourceFormModal = ({ resource, onClose, isEdit }: IResourceFormModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const { mutateAsync: onResourceUpdate, isLoading: isUpdateLoading } = useNyisoResourceUpdateMutation();
    const { mutateAsync: onResourceCreate, isLoading: isCreateLoading } = useNyisoResourceCreateMutation();
    const [file, setFile] = useState<UploadFile | null>(null);

    const onResourceModalFormFinish = async (formData: INyisoFormData) => {
        try {
            if (isEdit) {
                await onResourceUpdate(NyisoFormData.toEntity(formData, file) as IUpdateNyisoResource);
            } else {
                await onResourceCreate(NyisoFormData.toEntity(formData, file) as ICreateNyisoResource);
            }
            onClose();
        } catch (err) {
            console.log('Resource action failed with error: ', err);
        }
    };

    const onModalClose = () => {
        onClose();
    };

    const uploadProps = {
        accept: ALLOWED_FILE_TYPE,
        multiple: false,
        maxCount: 1,
        onRemove: () => {
            setFile(null);
        },
        beforeUpload: (file: UploadFile) => {
            const fileSizeInMB = file.size! / 1024 / 1024;
            if (fileSizeInMB > ALLOWED_FILE_SIZE_MB) {
                notification.warning({
                    key: 'upload-file-size-notification',
                    message: `Please select file up to ${ALLOWED_FILE_SIZE_MB}MB.`,
                });
                return Upload.LIST_IGNORE;
            }

            if (!file.name.endsWith(ALLOWED_FILE_TYPE)) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPE}" file.`,
                });
                return Upload.LIST_IGNORE;
            }

            return false;
        },
        onChange: (info: { file: UploadFile; fileList: UploadFile[] }) => {
            if (info.file.status) {
                return;
            }
            setFile(info.file);
        },
    };

    const preventNonNumericCharacters = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const NUMBER_REGEX = /^\d$/;
        if (!NUMBER_REGEX.test(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Modal
            open
            destroyOnClose
            title={isEdit ? 'Edit Resource' : 'Add Resource'}
            onCancel={onModalClose}
            footer={[
                <Button key="resource-modal-cancel" onClick={onModalClose}>
                    Cancel
                </Button>,
                <Button
                    key="resource-modal-submit"
                    type="primary"
                    loading={isUpdateLoading || isCreateLoading}
                    onClick={form.submit}
                >
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="nyiso-resource-form"
                preserve={false}
                layout="vertical"
                onFinish={onResourceModalFormFinish}
                initialValues={NyisoFormData.fromEntity(resource)}
                className="nyiso-resource-form"
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="resource_name"
                            label={<Typography.Text strong>Resource Name</Typography.Text>}
                            hasFeedback
                            validateDebounce={500}
                            rules={[
                                { required: true, message: 'Please enter name!' },
                                { max: 100, message: 'Number of characters should be less than 100' },
                                {
                                    required: true,
                                    validator: (_, value) =>
                                        checkNyisoResourceDuplicate(_, value, 'resource_name', resource?.id),
                                    message: 'Resource with this Name already exists',
                                },
                            ]}
                        >
                            <Input size="large" autoComplete="off" placeholder="Resource Name" data-1p-ignore />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="enrollment_date"
                            label={<Typography.Text strong>Enrollment Date</Typography.Text>}
                        >
                            <DatePicker
                                size="large"
                                placeholder="Select Date"
                                style={{ width: '100%' }}
                                inputReadOnly
                                format="MMM DD, YYYY"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="resource_id"
                            label={<Typography.Text strong>Resource ID</Typography.Text>}
                            hasFeedback
                            validateDebounce={500}
                            rules={[
                                { required: true, message: 'Please enter ID!' },
                                { pattern: /^\d+$/, message: 'Only digital characters are supported' },
                                { max: 128, message: 'Number of characters should be less than 128' },
                                {
                                    required: true,
                                    validator: (_, value) =>
                                        checkNyisoResourceDuplicate(_, value, 'resource_id', resource?.id),
                                    message: 'Resource with this ID already exists',
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Resource ID"
                                autoComplete="off"
                                data-1p-ignore
                                onKeyPress={preventNonNumericCharacters}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="to_service_voltage_id"
                            label={<Typography.Text strong>TO Service Voltage ID</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter value!' },
                                { pattern: /^\d+$/, message: 'Only digital characters are supported' },
                                { max: 2, message: 'Value should contain one or two digits!' },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="TO Service Voltage ID"
                                autoComplete="off"
                                data-1p-ignore
                                onKeyPress={preventNonNumericCharacters}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="aggregation_id"
                            label={<Typography.Text strong>Aggregation ID</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter value!' },
                                { pattern: /^\d+$/, message: 'Only digital characters are supported' },
                                { max: 128, message: 'Number of characters should be less than 128' },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Aggregation ID"
                                autoComplete="off"
                                data-1p-ignore
                                onKeyPress={preventNonNumericCharacters}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="to_account_number"
                            label={<Typography.Text strong>TO Account Number</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter value!' },
                                { max: 30, message: 'Number of characters should be less than 30' },
                                {
                                    pattern: /^[RTN]\d+$/,
                                    message: 'Only R, N or T characters followed by number are supported',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="TO Account Number" autoComplete="off" data-1p-ignore />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="meter_authority"
                            label={<Typography.Text strong>Meter Authority</Typography.Text>}
                            required
                            rules={[{ required: true, message: 'Please select value!' }]}
                        >
                            <Select size="large" placeholder="Select Meter Authority" options={NyisoMeterAuthorities} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="transmission_owner"
                            label={<Typography.Text strong>Transmission Owner</Typography.Text>}
                            required
                            rules={[{ required: true, message: 'Please select value!' }]}
                        >
                            <Select
                                size="large"
                                placeholder="Select Transmission Owner"
                                options={NyisoMeterAuthorities}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="street"
                            label={<Typography.Text strong>Street</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter street!' },
                                { max: 255, message: 'Number of characters should be less than 255' },
                            ]}
                        >
                            <Input size="large" placeholder="Street" autoComplete="off" data-1p-ignore />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            name="street2"
                            label={<Typography.Text strong>Street 2</Typography.Text>}
                            hasFeedback
                            rules={[{ max: 255, message: 'Number of characters should be less than 255' }]}
                        >
                            <Input size="large" placeholder="Street 2" autoComplete="off" data-1p-ignore />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="city"
                            label={<Typography.Text strong>City</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter city!' },
                                { max: 255, message: 'Number of characters should be less than 255' },
                            ]}
                        >
                            <Input size="large" placeholder="City" autoComplete="off" data-1p-ignore />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="state" label={<Typography.Text strong>State</Typography.Text>} hasFeedback>
                            <Select size="large" disabled options={NyisoStates} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="zip"
                            label={<Typography.Text strong>Zip Code</Typography.Text>}
                            hasFeedback
                            validateDebounce={500}
                            rules={[
                                { required: true, message: 'Please enter zip code!' },
                                { pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/, message: 'Zip code is not valid' },
                            ]}
                        >
                            <Input size="large" placeholder="Zip Code" autoComplete="off" data-1p-ignore />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="zone"
                            label={<Typography.Text strong>Zone</Typography.Text>}
                            required
                            rules={[{ required: true, message: 'Please select value!' }]}
                        >
                            <Select size="large" placeholder="Select Zone" options={NyisoLoadZones} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="cbl_method"
                            label={<Typography.Text strong>CBL Method</Typography.Text>}
                            required
                            rules={[{ required: true, message: 'Please select value!' }]}
                        >
                            <Select size="large" placeholder="Select CBL Method" options={NyisoCBLMethods} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="response_type"
                            label={<Typography.Text strong>Response Type</Typography.Text>}
                            required
                            rules={[{ required: true, message: 'Please select value!' }]}
                        >
                            <Select
                                size="large"
                                placeholder="Select Response Type"
                                options={NyisoResponseTypeOptions}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="provisional_acl"
                            label={<Typography.Text strong>Provisional ACL</Typography.Text>}
                            required
                            rules={[{ required: true, message: 'Please select value!' }]}
                        >
                            <Select
                                size="large"
                                placeholder="Select Provisional ACL"
                                options={NyisoProvisionalACLOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider plain>Interval Data</Divider>

                <Form.Item
                    name="enrollment_id"
                    label={<Typography.Text strong>Enrollment ID</Typography.Text>}
                    validateDebounce={500}
                    rules={[
                        { required: true, message: 'Please enter enrollment id!' },
                        {
                            required: true,
                            validator: (_, value) =>
                                checkNyisoResourceDuplicate(_, value, 'enrollment_id', resource?.id),
                            message: 'Resource with this Enrollment ID already exists',
                        },
                    ]}
                >
                    <DebounceSelect
                        fetchOptions={getUniqueSansForAutocomplete}
                        size="large"
                        maxOptions={1000}
                        showSearch
                        placeholder="Start typing to see the available options"
                    />
                </Form.Item>

                <Form.Item>
                    <Dragger {...uploadProps} height={120}>
                        <Row align="middle" justify="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                            <Col xs={24}>
                                <InboxOutlined style={{ fontSize: '20px', marginRight: '4px', color: PRIMARY_COLOR }} />
                                <Typography.Text>Click or drag CSV file to this area to upload</Typography.Text>
                                <Typography.Text type="secondary" style={{ display: 'block' }}>
                                    The size limit is {ALLOWED_FILE_SIZE_MB}MB
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Dragger>
                </Form.Item>

                {resource?.energy_data_files?.length ? (
                    <EnergyDataFileInfo file={orderBy(resource.energy_data_files, 'date_created', 'desc')[0]} />
                ) : null}
            </Form>
        </Modal>
    );
};

export async function checkNyisoResourceDuplicate(
    _: any,
    value: string,
    fieldName: NyisoResourceUniqueFields,
    resourceId: number | null = null
) {
    if (!value) {
        return Promise.resolve();
    }
    try {
        const duplicatedRecord = await checkForNyisoResourceDuplicateByField(fieldName, value);
        if (duplicatedRecord && duplicatedRecord.id !== resourceId) {
            return Promise.reject();
        }
    } catch (err) {
        // Silent because we do not want to prevent form submission
        console.log('Resource check failed with error: ', err);
    }

    return Promise.resolve();
}
