import { PageHeader } from '../../components/pageHeader/pageHeader';
import Typography from 'antd/lib/typography';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Badge from 'antd/lib/badge';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../domain/commonConst';
import { INyisoResource, NYISO_DATE_FORMAT } from '../../domain/nyiso/interface';
import EditOutlined from '@ant-design/icons/EditOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { ActionList } from '../../components/table/actionList/actionList';
import { ExclamationCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import App from 'antd/lib/app';
import {
    useNyisoResourceDeleteMutation,
    useNyisoMultipleResourcesToggleMutation,
    useNyisoResourcesListQuery,
    useNyisoSeasonQuery,
} from '../../domain/nyiso/queries';
import { ResourceStatus } from './components/ResourceStatus';
import { ResourceEnableStateSwitch } from './components/ResourceEnableStateSwitch';
import { useState, useMemo } from 'react';
import { ResourceFormModal } from './components/ResourceFormModal';
import { usePageLocation } from '../usePageState';
import { useLocation } from 'react-router-dom';
import { toUsefulParams } from '../toUsefulParams';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import './nyiso.css';
import { WARNING_COLOR } from '../../theme';
import Popover from 'antd/lib/popover';
import { getCurrentSeasonInfo } from '../../domain/nyiso/getCurrentSeasonInfo';
import { PeakHoursUploadModal } from './components/PeakHoursUploadModal';
import { getComputerFormattedTime } from '../../domain/date/date';

export const NyisoResourcesList = () => {
    const { modal, notification } = App.useApp();
    useDocumentTitle('NYISO');

    const { mutate: deleteResource, isLoading: isResourceDeleteLoading } = useNyisoResourceDeleteMutation();
    const { mutate: toggleAllResources, isLoading: isResourcesToggleLoading } =
        useNyisoMultipleResourcesToggleMutation();
    const [resource, setResource] = useState<INyisoResource | Partial<INyisoResource> | null>(null);
    const [pageAction, setPageAction] = useState<PAGE_ACTION | ''>();

    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
    };

    const {
        data: resources,
        isLoading,
        isError,
        error,
    } = useNyisoResourcesListQuery(listQuery, {
        keepPreviousData: true,
    });

    if (isError) {
        notification.error({
            key: 'nyiso-resources-fetch-error',
            message: error?.message || 'Cannot fetch NYISO resources!',
        });
    }

    const currentSeasonInfo = useMemo(() => getCurrentSeasonInfo(), []);

    const {
        data: seasonData,
        isLoading: isSeasonLoading,
        isError: isSeasonError,
        error: seasonError,
    } = useNyisoSeasonQuery(currentSeasonInfo.id);

    const isSeasonalDataMissing = !seasonData && !isSeasonError && !isSeasonLoading;

    if (isSeasonError) {
        notification.error({
            key: 'nyiso-season-fetch-error',
            message: seasonError?.message || 'Cannot fetch NYISO season!',
        });
    }

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });
        setPageQuery({ ...pageState, ...preparedParams });
    }

    const onResourceFormModalOpen = (record?: any) => {
        setPageAction(PAGE_ACTION.NEW_EDIT);
        setResource(
            record || {
                resource_name: undefined,
                resource_id: undefined,
            }
        );
    };

    const onResourceModalClose = () => {
        setPageAction('');
        setResource(null);
    };

    const onSeasonModalClose = () => {
        setPageAction('');
    };

    const onSeasonModalOpen = () => {
        setPageAction(PAGE_ACTION.UPLOAD_FILE);
    };

    const onEnrollmentFileRequest = () => {
        console.log(`Requesting the enrollment file`);
    };

    function deleteResourceWithConfirm(record: INyisoResource) {
        if (isResourceDeleteLoading) return;

        modal.confirm({
            icon: null,
            title: 'Confirm Delete',
            closeIcon: true,
            closable: true,
            width: 500,
            styles: {
                body: {
                    padding: '24px 24px 16px 24px',
                },
            },
            content: (
                <div>
                    <ExclamationCircleOutlined style={{ fontSize: 14, color: WARNING_COLOR, marginRight: 8 }} />
                    <Typography.Text>
                        Deleting NYISO resource {record.resource_name || ''}. Are you sure?
                    </Typography.Text>
                </div>
            ),
            onOk() {
                deleteResource(record.id);
            },
            onCancel() {
                console.log('Resource deletion canceled');
            },
        });
    }

    const actions = [
        {
            title: 'Edit',
            onClick: (record: INyisoResource) => () => onResourceFormModalOpen(record),
            icon: <EditOutlined />,
        },
        {
            title: 'Delete',
            onClick: (record: INyisoResource) => () => deleteResourceWithConfirm(record),
            icon: <DeleteOutlined />,
        },
    ];

    const columns: ColumnsType<INyisoResource> = [
        {
            title: 'Resource Name',
            dataIndex: 'resource_name',
            render: (text, record) => <div>{record.resource_name}</div>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
                <div>
                    <ResourceStatus isEnabled={record.is_enabled} status={record.status} />
                </div>
            ),
        },
        {
            title: 'Aggregation Id',
            dataIndex: 'aggregation_id',
            render: (text, record) => <div>{record.aggregation_id}</div>,
        },
        {
            title: 'Enrollment Date',
            dataIndex: 'enrollment_date',
            render: (text, record) => {
                if (!record.enrollment_date) {
                    return <></>;
                }
                return <div>{getComputerFormattedTime(new Date(text), NYISO_DATE_FORMAT)}</div>;
            },
        },
        {
            title: 'Enabled',
            dataIndex: 'is_enabled',
            render: (text, record) => (
                <div>
                    <ResourceEnableStateSwitch resource={record} />
                </div>
            ),
        },
        {
            key: 'actions',
            sorter: false,
            width: 100,
            render: (text: string, record) => <ActionList actions={actions} item={record} />,
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle={
                    <span style={{ textTransform: 'capitalize' }}>
                        NYISO {currentSeasonInfo.viewValue}{' '}
                        {isSeasonalDataMissing && (
                            <Popover
                                content={
                                    <>
                                        <div>Peak hours data for current season is missing!</div>
                                        <div>Upload it using the 'Upload Peak Hours' button.</div>
                                    </>
                                }
                            >
                                <WarningTwoTone twoToneColor={WARNING_COLOR} style={{ fontSize: 'large' }} />
                            </Popover>
                        )}
                    </span>
                }
                actions={[
                    <Button key="nyiso-enable-all" size="large" onClick={() => toggleAllResources({ enable: true })}>
                        Enable All
                    </Button>,
                    <Button key="nyiso-disable-all" size="large" onClick={() => toggleAllResources({ enable: false })}>
                        Disable All
                    </Button>,
                    <Badge dot offset={[-3, 3]} count={isSeasonalDataMissing ? 1 : 0} key="nyiso-seasonal-data">
                        <Button
                            size="large"
                            icon={<UploadOutlined />}
                            onClick={onSeasonModalOpen}
                            disabled={isSeasonError || isSeasonLoading}
                        >
                            Upload Peak Hours
                        </Button>
                    </Badge>,
                    <Button
                        key="nyiso-download"
                        size="large"
                        disabled
                        icon={<DownloadOutlined />}
                        onClick={onEnrollmentFileRequest}
                    >
                        Download Enrollment File
                    </Button>,
                    <Button
                        key="nyiso-add-resource"
                        size="large"
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => onResourceFormModalOpen()}
                    >
                        Add Resource
                    </Button>,
                ]}
            />

            <WithEmptyDataTable condition={isLoading || isResourcesToggleLoading}>
                <Table
                    size="small"
                    rowKey="resource_id"
                    className="nyiso-list-table"
                    rowClassName={record => (record.is_enabled ? '' : 'nyiso-disabled-row')}
                    sticky
                    columns={columns}
                    dataSource={resources?.data}
                    loading={isLoading || isResourcesToggleLoading}
                    onChange={onTableChange}
                    pagination={{
                        ...DEFAULT_PAGINATION,
                        ...(resources?.meta && {
                            total: resources?.meta.total,
                            current: Math.ceil(resources?.meta.offset / resources?.meta.limit) + 1,
                            pageSize: resources?.meta.limit,
                        }),
                        size: 'default',
                        showSizeChanger: true,
                    }}
                />
            </WithEmptyDataTable>
            {resource && pageAction === PAGE_ACTION.NEW_EDIT && (
                <ResourceFormModal resource={resource} onClose={onResourceModalClose} isEdit={!!resource.resource_id} />
            )}
            {pageAction === PAGE_ACTION.UPLOAD_FILE && (
                <PeakHoursUploadModal
                    peakHoursData={seasonData?.peak_hours}
                    currentSeasonInfo={currentSeasonInfo}
                    onClose={onSeasonModalClose}
                />
            )}
        </>
    );
};
