import React, { useState } from 'react';
import { get, some } from 'lodash';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Dragger from 'antd/lib/upload/Dragger';
import Tooltip from 'antd/lib/tooltip';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { COMPANY_TYPE, ICompany } from '../../../domain/company/interface';
import { useProgramListQuery } from '../../../domain/program/queries';
import { useCompanyListQuery } from '../../../domain/company/queries';
import { IProgram } from '../../../domain/program/interface';
import { ImportProgress } from '../../ImportProgress/ImportProgress';
import { createSite } from '../../../domain/site';
import { importedRecordToCreateSite } from './importedRecordToCreateSite';
import { SitesImportFormatDescription } from './SitesImportFormatDescription';
import { ERROR_COLOR, WARNING_COLOR, PRIMARY_COLOR } from '../../../theme';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';
import { SitesParser } from './SitesParser';
import './ImportSitesModal.css';
import { getDuplicatedSitesByStoreNumber } from './getDuplicatedSitesByStoreNumber';
import { Upload } from 'antd/lib';
import App from 'antd/lib/app';

interface IImportSitesModalProps {
    onCancel: (refetchSites?: boolean) => void;
}

const csvColumns: string[] = [
    'Company Name*',
    'Control Provider Name',
    'Program Names(comma-separated)',
    'Site Name*',
    'Site Store Number',
    'Address*',
    'City*',
    'State*',
    'Zip*',
    'Market',
    'Load Zone',
    'Estimated KW',
    'Enrollment ID',
    'Utility Name',
    'Zone',
    'Network',
    'Event Offset(minutes)',
    'Event Max Duration(minutes)',
    'Create VEN (put 1 to create)',
    'Partner',
];

const ALLOWED_FILE_TYPE = '.csv';

export const ImportSitesModal = ({ onCancel }: IImportSitesModalProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [errors, setErrors] = useState<string[][]>([]);
    const [warnings, setWarnings] = useState<string[][]>([]);
    const [importErrors, setImportErrors] = useState<string[]>([]);
    const [records, setRecords] = useState<any[][]>([]);
    const [importState, setImportState] = useState<{ imported: number; failed: number; total: number } | null>(null);
    const [paginationData, setPaginationData] = useState<TablePaginationConfig>();

    const { data: companiesResult } = useCompanyListQuery({});
    const companies = companiesResult?.data || [];

    const { data: programsResult } = useProgramListQuery({});
    const programs = programsResult?.data || [];

    const { notification } = App.useApp();

    const importHasErrors = () => {
        // Check if any cell has static validation error
        const hasErrors = some(errors, row => some(row, Boolean));

        // Check if any cell has api validation error
        const hasImportErrors = some(importErrors, Boolean);

        return hasErrors || hasImportErrors;
    };

    const readyToImport = () => {
        if (!records.length) return false;

        return !importHasErrors();
    };

    const handleConfirm = async () => {
        if (!records.length) return;

        let imported = 0;
        let failed = 0;
        let total = records.length;
        setImportState({ imported, failed, total });

        const _companies: ICompany[] = companies.filter(company => company.type === COMPANY_TYPE.CUSTOMER_COMPANY);
        const _providers: ICompany[] = companies.filter(company => company.type === COMPANY_TYPE.CONTROL_PROVIDER);
        const _programs: IProgram[] = programs;

        setLoading(true);
        let row = 0;
        for await (let record of records) {
            try {
                const dto = importedRecordToCreateSite(record, _companies, _providers, _programs);
                await createSite(dto);

                imported++;
                setImportState({ imported, failed, total });
            } catch (error: any) {
                failed++;
                setImportState({ imported, failed, total });
                importErrors[row] = error.message || 'Import site failed!';
                setImportErrors(importErrors);
            }
            row++;
        }
        setLoading(false);
    };

    const handleCancel = () => {
        if (importState?.total) {
            // if imported some data - return true, so that parent component can refresh list of sites
            onCancel(true);
        } else {
            // if not imported
            onCancel();
        }
    };

    const parseCsv = (content: string) => {
        Papa.parse<any[]>(content, {
            dynamicTyping: false, // we do not convert types automatically (because of some issues), we handle it manually from string
            transform: (item: string, index: number) => {
                return item.trim();
            },
            skipEmptyLines: true,
            complete: async function (results) {
                const maxColumns = csvColumns.length;

                const importErrors = Array(results.data.length).fill(null);
                setImportErrors(importErrors);

                if (results.data.length === 0) {
                    setRecords([]);
                    setErrors([]);
                    setWarnings([]);
                    return;
                }

                const hasHeader = results.data[0].some(
                    (col: string) => col && String(col).toLowerCase().includes('site name*')
                );
                if (hasHeader) {
                    results.data.shift();
                }

                const data = results.data.map(record => {
                    const row = Array(maxColumns).fill('');
                    for (let i = 0; i < record.length; i++) {
                        if (i >= maxColumns) continue;
                        row[i] = record[i];
                    }
                    return row;
                });

                const _companies: ICompany[] = companies.filter(
                    company => company.type === COMPANY_TYPE.CUSTOMER_COMPANY
                );
                const _providers: ICompany[] = companies.filter(
                    company => company.type === COMPANY_TYPE.CONTROL_PROVIDER
                );
                const _programs: IProgram[] = programs;

                const duplicatedSites = await getDuplicatedSitesByStoreNumber(data, _companies);

                const parser = new SitesParser();
                const [records, errors, warnings] = parser.parse(
                    data,
                    _companies,
                    _providers,
                    _programs,
                    duplicatedSites
                );

                setRecords(records);
                setErrors(errors);
                setWarnings(warnings);
            },
        });
    };

    const uploadProps = {
        accept: '.csv',
        multiple: false,
        onRemove: (file: any) => {
            setFile(null);
            setErrors([]);
            setWarnings([]);
            setRecords([]);
        },
        beforeUpload: (file: File) => {
            if (!file.name.endsWith(ALLOWED_FILE_TYPE)) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPE}" file.`,
                });
                return Upload.LIST_IGNORE;
            }
            setErrors([]);
            setWarnings([]);
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e: any) => {
                const content = e.target.result;
                parseCsv(content);
            };

            return false;
        },
        onChange: (info: any) => {
            if (info.file.status) {
                return;
            }
            setFile(info.file);
        },
        maxCount: 1,
    };

    const downloadTemplate = () => {
        const csvData = Papa.unparse([csvColumns], {
            quotes: true,
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ',',
            header: true,
            escapeFormulae: true,
        });
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'import-sites-template.csv');
    };

    const downloadErrors = () => {
        let data = [[...csvColumns, 'Errors']];

        for (let row = 0; row < records.length; row++) {
            const rowErrors = errors[row].filter(item => item);
            const rowImportError = importErrors[row];
            if (rowImportError) {
                rowErrors.push(rowImportError);
            }

            data.push([...records[row], rowErrors.join(' | ')]);
        }

        const csvData = Papa.unparse(data, {
            quotes: true,
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ',',
            header: true,
            escapeFormulae: true,
        });

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        let importedFileName = file?.name || 'import';
        importedFileName = importedFileName.replace('.csv', '').replace('.CSV', '');
        saveAs(blob, `${importedFileName}-errors.csv`);
    };

    const getItemIndex = (rowIndex: number) => {
        const currentPage = paginationData?.current || 1;
        const itemsPerPage = paginationData?.pageSize || 10;
        return rowIndex + (currentPage - 1) * itemsPerPage;
    };

    const getAllRowErrors = (rowIndex: number) => {
        const itemIndex = getItemIndex(rowIndex);
        const rowErrors = get(errors, itemIndex, []).filter(item => !!item);

        const importError = importErrors[itemIndex];
        if (importErrors[itemIndex]) {
            return rowErrors.concat(importError);
        }

        return rowErrors;
    };

    const getAllRowWarnings = (rowIndex: number) => {
        const itemIndex = getItemIndex(rowIndex);
        return get(warnings, itemIndex, []).filter(item => !!item);
    };

    const rowHasError = (rowIndex: number) => {
        const itemIndex = getItemIndex(rowIndex);
        const rowErrors = errors?.[itemIndex] || [];
        return rowErrors.some(col => col) || importErrors[itemIndex];
    };

    const rowHasWarning = (rowIndex: number) => {
        const itemIndex = getItemIndex(rowIndex);
        const rowWarnings = warnings?.[itemIndex] || [];
        return rowWarnings.some(col => col);
    };

    const colHasError = (rowIndex: number, colIndex: number) => {
        const itemIndex = getItemIndex(rowIndex);
        return errors?.[itemIndex]?.[colIndex];
    };

    const colHasWarning = (rowIndex: number, colIndex: number) => {
        const itemIndex = getItemIndex(rowIndex);
        return warnings?.[itemIndex]?.[colIndex];
    };

    const getRowClassName = (index: number): string => {
        if (rowHasError(index)) {
            return 'row-with-error';
        }

        if (rowHasWarning(index)) {
            return 'row-with-warning';
        }

        return '';
    };

    const getColClassName = (rowIndex: number, colIndex: number): string => {
        if (colHasError(rowIndex, colIndex)) {
            return 'col-with-error';
        }

        if (colHasWarning(rowIndex, colIndex)) {
            return 'col-with-warning';
        }

        return '';
    };

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [];

        csvColumns.forEach((columnName, colIndex) => {
            columns.push({
                title: columnName,
                dataIndex: [colIndex],
                width: 150,
                ellipsis: true,
                render: (text: number, record: any, index: number) => {
                    return <div className={getColClassName(index, colIndex)}>{text}</div>;
                },
            });
        });

        columns.unshift(
            // Add Import Status column
            {
                title: 'Status',
                dataIndex: 'status',
                width: 60,
                align: 'center',
                render: (text: number, record: any, index: number) => {
                    const rowErrors = getAllRowErrors(index);
                    const rowWarnings = getAllRowWarnings(index);

                    if (rowErrors.length) {
                        return (
                            <Tooltip title="Please, check 'Errors' column">
                                <CloseOutlined style={{ color: ERROR_COLOR }} />
                            </Tooltip>
                        );
                    }

                    if (rowWarnings.length) {
                        return (
                            <Tooltip title="Please, check 'Warnings' column">
                                <WarningOutlined style={{ color: WARNING_COLOR }} />
                            </Tooltip>
                        );
                    }

                    return <CheckOutlined style={{ color: PRIMARY_COLOR }} />;
                },
            },

            // Add Import Errors column
            {
                title: 'Errors',
                dataIndex: 'errors',
                width: 200,
                render: (text: number, record: any, index: number) => {
                    const rowErrors = getAllRowErrors(index) || [];

                    if (rowErrors.length === 0) {
                        return '';
                    }

                    const errorsMessage = rowErrors.join(' | ');

                    return <Tooltip title={errorsMessage}>{toAllowedStringLength(errorsMessage, 50)}</Tooltip>;
                },
            },
            ...(some(warnings, row => some(row, Boolean))
                ? [
                      {
                          title: 'Warnings',
                          dataIndex: 'warnings',
                          width: 200,
                          render: (text: number, record: any, index: number) => {
                              const rowWarnings = getAllRowWarnings(index) || [];

                              if (rowWarnings.length === 0) {
                                  return '';
                              }

                              const warningMessage = rowWarnings.join(' | ');

                              return (
                                  <Tooltip title={warningMessage}>{toAllowedStringLength(warningMessage, 50)}</Tooltip>
                              );
                          },
                      },
                  ]
                : [])
        );

        return columns;
    };

    function makeRowKeyClosure(prefix: string) {
        let rowIndex = 0;
        return () => `${prefix}-${rowIndex++}`;
    }

    const actions: JSX.Element[] = [];
    if (importState) {
        actions.push(
            <ImportProgress state={importState} className="import-sites-progress" key="import-sites-progress" />
        );
    }

    actions.push(
        <Tooltip
            title="Click to download a CSV template that you could fill in with your data to import"
            key="import-sites-modal-template-tooltip"
        >
            <Button key="import-sites-modal-template" type="text" onClick={downloadTemplate}>
                Download Template
            </Button>
        </Tooltip>
    );

    if (importHasErrors()) {
        actions.push(
            <Tooltip title="Click to download a CSV file with import result" key="import-sites-modal-errors-tooltip">
                <Button key="import-sites-modal-errors" type="text" onClick={downloadErrors}>
                    Download Import Result
                </Button>
            </Tooltip>
        );
    }

    actions.push(
        <Button key="import-sites-modal-cancel" onClick={handleCancel}>
            Close
        </Button>
    );

    if (!importState?.total) {
        const buttonText = records.length === 1 ? 'Import site' : `Import ${records.length} sites`;

        actions.push(
            <Button
                key="import-sites-modal-submit"
                type="primary"
                loading={loading}
                disabled={loading || !readyToImport()}
                onClick={handleConfirm}
            >
                {buttonText}
            </Button>
        );
    }

    return (
        <Modal title="Import Sites" width="80%" open destroyOnClose onCancel={handleCancel} footer={actions}>
            {!records?.length && (
                <>
                    <Dragger {...uploadProps} key="import-sites-dragger">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area</p>
                        <p className="ant-upload-hint">Allowed file extensions: .csv</p>
                        <p className="ant-upload-hint">You can download the import template csv.</p>
                    </Dragger>
                    <p></p>
                    <SitesImportFormatDescription key="import-sites-format" />
                </>
            )}

            {records?.length > 0 && (
                <Table
                    rowKey={makeRowKeyClosure('import-sites')}
                    size="small"
                    className="import-sites-table"
                    rowClassName={(row, index) => getRowClassName(index)}
                    scroll={{ x: 'scroll' }}
                    sticky
                    columns={buildColumns()}
                    dataSource={records}
                    onChange={pagination => setPaginationData(pagination)}
                ></Table>
            )}
        </Modal>
    );
};
