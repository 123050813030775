export interface IIntervalDataReporting {
    sites: {
        total: number;
        enrolled: number;
        canBeEnrolled: number;
        unenrolled: number;
    };
    partner: {
        greenbutton: number;
        leap: number;
        smartMeterTx: number;
        other: number;
    };
    utility: {
        [ key: string ]: {
            enrolled: number;
            canBeEnrolled: number;
        }
    }
}

export enum INTERVAL_DATA_INITIAL_UTILITY {
    "CAISO/SDGE" = "CAISO/SDGE",
    'CAISO/SCE' = 'CAISO/SCE',
    'ERCOT' ='ERCOT',
    'CAISO/PGE'='CAISO/PGE'
}