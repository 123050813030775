import { IFetchAlertTrackQuery } from './interface';
import { ITrackingAlert } from '../event/interface';
import { API } from '../api';

export const fetchTrackingAlerts = async ({
    companyId = null,
    search,
    start,
    end
}: IFetchAlertTrackQuery, signal?: AbortSignal): Promise<ITrackingAlert[]> => {
    const path = `/alert/tracking`;

    const response = await API.fetch(
        `${path}?${new URLSearchParams({
            ...(companyId && { companyId: companyId.toString() }),
            ...(search && { search }),
            ...(start && { start: start.toISOString() }),
            ...(end && { end: end.toISOString() }),
        })}`,
        {
            signal,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || 'Cannot fetch alert tracks logs');
};
