import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { DescriptionsProps } from 'antd';
import Alert from 'antd/lib/alert';
import Descriptions from 'antd/lib/descriptions';
import Flex from 'antd/lib/flex';
import Space from 'antd/lib/space';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import React, { useContext, useMemo } from 'react';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';
import {
    checkIfLoadZoneSupportsPriceResponse, checkIfMarketSupportsCleanResponse,
} from '../../../domain/site/useMarketZoneOptions';
import { WARNING_COLOR } from '../../../theme';
import { SiteAddress } from '../SiteAddress';
import { IMergingSite } from './interface';
import { IMergeSitesContext, MergeSitesContext } from './MergeSitesContext';
import { MergeSitesMap } from './MergeSitesMap';
import { ShortVenInfo } from './ShortVenInfo';
import { MergeSitesTableUtils } from './MergeSitesTableUtils';
import { YearlyEventsCountInfo } from './YearlyEventsCountInfo';

export const MergeSitesResultPreview: React.FC = () => {
    const { resultsColumnData, sites, eventsCountBySites, mergeSitesMutation } = useContext<IMergeSitesContext>(MergeSitesContext);
    const resultedSite = useMemo(() => MergeSitesTableUtils.mergeResultsColumnValues(resultsColumnData), [resultsColumnData]);
    const companyName = useMemo(() => sites[0]?.company?.company_name || '', [sites]);

    const items: DescriptionsProps['items'] = [
        {
            key: 'company_name',
            label: 'Company',
            children: companyName,
        },
        {
            key: 'site_name',
            label: 'Name',
            children: resultedSite.site_name,
        },
        {
            key: 'control_provider_name',
            label: 'Control Provider',
            children: resultedSite.control_provider_name,
        },
        {
            key: 'site_address',
            label: 'Location',
            children: (
                <SiteAddress
                    address={resultedSite.location.site_address}
                    city={resultedSite.location.site_city}
                    state={resultedSite.location.site_state}
                />
            ),
        },
        {
            key: 'marketAndLoadZone',
            label: 'Load Zone',
            children: resultedSite.marketAndLoadZone,
        },
        {
            key: 'site_store_number',
            label: 'Store Number',
            children: resultedSite.site_store_number,
        },
        {
            key: 'site_utility',
            label: 'Utility',
            children: resultedSite.site_utility,
        },
        {
            key: 'site_network',
            label: 'Network',
            children: resultedSite.site_network,
        },
        {
            key: 'site_resource_id',
            label: 'Resource ID',
            children: resultedSite.site_resource_id,
        },
        {
            key: 'partner_id',
            label: 'Partner ID',
            children: resultedSite.partner_id,
        },
        {
            key: 'site_estimated_kw',
            label: 'Estimated kw',
            children: resultedSite.site_estimated_kw,
        },
        {
            key: 'event_offset',
            label: 'Event Offset',
            children: resultedSite.event_offset,
        },
        {
            key: 'event_max_duration',
            label: 'Event Max Duration',
            children: resultedSite.event_max_duration,
        },
        {
            key: 'site_highlight',
            label: 'Highlight',
            children: resultedSite.site_highlight ? 'Yes' : 'No',
        },
        {
            key: 'email_only_integration',
            label: 'Email Only Integration',
            children: resultedSite.email_only_integration ? 'Yes' : 'No',
        },
        {
            key: 'sans',
            label: 'Enrollment IDs',
            children: resultedSite.sans.map((san) => (
                <Tooltip title={san} key={san}>
                    <Tag color='gold' style={{ marginBottom: 4 }}>{toAllowedStringLength(san)}</Tag>
                </Tooltip>
            )),
        },
        {
            key: 'programs',
            label: 'Programs',
            children: resultedSite.programs.map((program) => (
                <Tooltip title={program} key={program}>
                    <Tag color='gold' style={{ marginBottom: 4 }}>{toAllowedStringLength(program)}</Tag>
                </Tooltip>
            )),
        },
        {
            key: 'site_price_response_enrolled',
            label: 'Price Response Enrolled',
            children: <PriceResponseEnrollmentInfo site={resultedSite} />,
        },
        {
            key: 'site_clean_response_enrolled',
            label: 'Clean Response Enrolled',
            children: <CleanResponseEnrollmentInfo site={resultedSite} />,
        },
        {
            key: 'customerVenFromSiteId',
            label: 'Customer VEN',
            children: resultedSite['customerVenFromSiteId']
                ? <ShortVenInfo ven={MergeSitesTableUtils.getCustomerVenBySiteId(resultedSite['customerVenFromSiteId'], sites)} />
                : null,
        },
        {
            key: 'cloudVensFromSiteIds',
            label: 'Cloud VENs',
            children: resultedSite['cloudVensFromSiteIds'].map((siteId) => (
                MergeSitesTableUtils.getCloudVensBySiteIds([siteId], sites).map((ven) => (
                    <ShortVenInfo key={ven.ven_id} ven={ven} />
                ))
            )),
        },
        {
            key: 'eventsFromSiteId',
            label: 'Events',
            children: (
                <YearlyEventsCountInfo
                    eventsCountByYear={MergeSitesTableUtils.getEventsCountDataBySiteId(resultedSite['eventsFromSiteId'], eventsCountBySites)}
                />
            ),
        },
        {
            key: 'site_label',
            label: 'Labels',
            children: resultedSite.site_label.map(label => (
                <Tooltip title={label} key={label}>
                    <Tag style={{ marginBottom: 4 }}>{toAllowedStringLength(label)}</Tag>
                </Tooltip>
            )),
        }
    ];

    return (
        <>
            <Descriptions
                items={items}
                title='Site Result'
                column={1}
                bordered
                size='middle'
                labelStyle={{ fontWeight: 'bold' }}
            />

            <Space style={{ maxWidth: '100%', padding: '16px 0', width: '100%' }} direction='vertical'>
                {mergeSitesMutation.isError && (
                    <Alert showIcon type='error' message={formatMergeSitesError(mergeSitesMutation.error)} />
                )}

                <Alert showIcon type='warning' message='Site will be unenrolled from Leap API dispatch' />
                <Alert showIcon type='warning' message='Baseline will not recalculate' />
                <Alert showIcon type='warning' message='Not selected VENs will be deleted!' />
                <Alert showIcon type='warning' message='Other sites except "primary" will be deleted!' />
            </Space>
        </>
    );
};

function CleanResponseEnrollmentInfo({ site }: { site: IMergingSite }) {
    const [market] = MergeSitesMap.getMarketZone(site.marketAndLoadZone);
    const canEnrollCleanResponse = checkIfMarketSupportsCleanResponse(market);

    const enrollStatus = site.site_clean_response_enrolled && canEnrollCleanResponse ? 'Yes' : 'No';
    const showWarning = !canEnrollCleanResponse && site.site_clean_response_enrolled;

    return (
        <Flex align='center'>
            {showWarning && (
                <Tooltip
                    title='Site cannot be enrolled to Clean Response, because we do not support selected Load Zone'
                >
                    <WarningOutlined size={16} style={{ color: WARNING_COLOR, marginRight: 4, marginBottom: 2 }} />
                </Tooltip>
            )}

            <Typography.Text>{enrollStatus}</Typography.Text>
        </Flex>
    );
}

function PriceResponseEnrollmentInfo({ site }: { site: IMergingSite }) {
    const [market, loadZone] = MergeSitesMap.getMarketZone(site.marketAndLoadZone);
    const canEnrollPriceResponse = checkIfLoadZoneSupportsPriceResponse(market, loadZone);

    const enrollStatus = site.site_price_response_enrolled && canEnrollPriceResponse ? 'Yes' : 'No';
    const showWarning = !canEnrollPriceResponse && site.site_price_response_enrolled;

    return (
        <Flex align='center'>
            {showWarning && (
                <Tooltip
                    title='Site cannot be enrolled to Price Response, because we do not support selected Load Zone'
                >
                    <WarningOutlined size={16} style={{ color: WARNING_COLOR, marginRight: 4, marginBottom: 2 }} />
                </Tooltip>
            )}

            <Typography.Text>{enrollStatus}</Typography.Text>
        </Flex>
    );
}

function formatMergeSitesError(error: any) {

    if (JSON.stringify(error).includes('sans must contain not more than 10 elements')) {
        return 'The site cannot have more than 10 enrollment IDs';
    }

    return error?.message || 'An error occurred while merging sites';
}
