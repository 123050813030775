import { IEnergyData, ISiteEnergyUsageData } from './interface';

/**
 *  function returns an array of readings for each site-san
 * (for now, we take first "source" of reading, but there could be more, or it may be filtered in future)
 * (later in code, charts summarize all values under the same timestamps)
 * */
export function getEnergyReadings(sitesEnergyUsageDataResults: ISiteEnergyUsageData[] | undefined): IEnergyData[] {
    const results: IEnergyData[] = [];

    if (!sitesEnergyUsageDataResults || sitesEnergyUsageDataResults?.length === 0) {
        return results;
    }

    sitesEnergyUsageDataResults.forEach(({ site, sanUsages }) => {
        sanUsages.forEach(({ readings: allSourcesReadings }) => {
            let readings = allSourcesReadings?.at(0);

            if (readings) {
                readings.values = readings?.values.map(([seconds, usage]) => {
                    return [seconds * 1000, usage];
                });
            }

            results.push({
                site,
                values: readings?.values || [],
                fields: readings?.fields || ['timestamp', 'usage'],
            });
        });
    });

    return results;
}
