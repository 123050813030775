import { IEnergyData } from '../energy/interface';
import { IEventData } from '../event/getEventsDataService';
import { IProgram } from '../program/interface';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { ICompany } from '../company/interface';
import { ICloudVen, ICustomerVen } from '../ven/interface';
import { PAGE_ACTION } from '../commonConst';
import { IAccount } from '../account/interface';

export enum SPECIFIC_SITE_ACTION {
    BATCH_UNENROLL_PROGRAMS = 'batch_unenroll_programs',
    ATTACH_PROGRAMS = 'attach programs',
    UPDATE_SPECIFIC_FIELDS = 'update specific fields',
    IMPORT = 'import',
    ADD_VEN = 'add_ven',
    MERGE = 'merge',
    CLOSE_SITE = 'close_site',
    OPEN_SITE = 'open_site',
}
export type SITE_ACTION = SPECIFIC_SITE_ACTION | PAGE_ACTION;

export interface IBatchUpdatedFields {
    control_provider_id?: number;
    event_offset?: number;
    event_max_duration?: number;
    site_estimated_kw?: number;
}

export interface IEnrollSiteToPrograms {
    programs?: number[];
    price_response?: boolean;
    clean_response?: boolean;
    leap_api_dispatch?: boolean;
}

export interface IEnrollSitesBatchToPrograms {
    sitesId: number[];
    programs?: number[];
    price_response?: boolean;
    clean_response?: boolean;
    leap_api_dispatch?: boolean;
}

export interface ISiteAddress {
    google_place_id?: string | null;
    site_address: string | null;
    site_city: string | null;
    site_state: string | null;
    site_zip: string | null;
    site_county: string | null;
    site_country: string | null;
    site_lat: number | null;
    site_long: number | null;
    site_timezone: string | null;
}

export enum AgreementStatus {
    ACTIVE = 'active',
    REVOKED = 'revoked',
    DENIED = 'denied',
    INACTIVE = 'inactive',
    PENDING = 'pending',
    COMPLETED = 'completed',
    UNKNOWN = 'unknown',
}

export interface IAgreement {
    status: AgreementStatus;
    message?: string;
    datetime: string;
    startAt?: string;
    endAt?: string;
}

export interface ISanFormItem {
    service_account_number: string;
    is_active: boolean;
}

export interface ISan {
    site_id: number;
    service_account_number: string;
    is_active: boolean;
    san_info?: ISanInfo | null;
}

export interface ISiteLocation {
    site_id: number;
    company_id: number;
    control_provider_id?: number;
    company_name: string;
    google_place_id?: string | null;
    site_name: string;
    lmp_node_name?: string;
    lmp_market?: string;
    lmp_price_rt?: number;
    lmp_price_da?: number;
    site_address: string;
    site_city: string;
    site_state: string;
    site_zip: string;
    site_country: string;
    site_lat: number | null;
    site_long: number | null;
    site_timezone: string;
    site_county: string | null;
    site_load_zone: string | null;
}

export interface ISite {
    site_id: number;
    company_id: number;
    control_provider_id?: number;
    lmp?: {
        lmp_node_name: string;
        lmp_price_da: number;
        lmp_price_rt: number;
        lmp_lat: number;
        lmp_long: number;
        lmp_date_last_modifed: Date;
        lmp_market: string;
    };
    google_place_id?: string | null;
    site_name: string;
    lmp_node_name?: string;
    lmp_market: string;
    site_address: string;
    site_city: string;
    site_state: string;
    site_zip: string;
    site_country: string;
    site_lat: number | null;
    site_long: number | null;
    site_timezone: string;
    site_store_number: string | null;
    site_estimated_kw: number;
    site_slap?: string;
    site_utility: string | null;
    site_vertical?: string;
    site_market_resource?: string;
    event_offset: number;
    site_county: string | null;
    site_load_zone: string;
    site_network?: string;
    event_max_duration?: number | null;
    site_resource_id?: string;
    site_price_response_enrolled: boolean;
    site_clean_response_enrolled: boolean;
    site_leap_api_dispatch_enrolled: boolean;
    weather_station: {
        radar_station_name: string;
        cwa: string;
    };
    closed_at: string;
    partner_id?: string;
    site_highlight: boolean;
    site_label: string[];
    can_enroll_interval_data: boolean;
    connect_url?: string;
    email_only_integration?: boolean;
    exist_salesforce_meters: boolean;
    partner_id_status: {
        status: PARTNER_ID_ERROR_STATUS;
        errors: { error: string; received_date: string }[];
    } | null;

    control_provider?: ICompany;
    sans: ISan[];
    company?: ICompany;
    programs: IProgram[];
    meters: IMeter[];
    meterEnrollments?: IMeterEnrollment[];
    customer_ven?: ICustomerVen;
    cloud_vens?: ICloudVen[];
}

export enum EnrollmentStatus {
    'NOT_SUBMITTED' = 'Not Submitted',
    'SUBMITTED' = 'Submitted',
    'ENROLLED' = 'Enrolled',
    'INELIGIBLE_FINAL' = 'Ineligible-Final',
    'INELIGIBLE_FOLLOW_UP' = 'Ineligible-Follow-up',
    'MOVED_PROGRAMS' = 'Moved Programs',
    'NOT_PARTICIPATING' = 'Not Participating',
    'DE_ENROLLED' = 'De-enrolled',
    'CLOSED' = 'Closed',
}

export interface IMeterEnrollment {
    salesforce_id: string;
    name: string;
    enrollment_id?: string;
    meter_salesforce_id?: string;
    meter?: IMeter;
    product_salesforce_id?: string;
    product_name?: string;
    sites?: ISite[];
    enrollment_status: EnrollmentStatus;
    salesforce_submitted_at?: Date;
    salesforce_created_at: Date;
    salesforce_created_by: string;
    salesforce_updated_at?: Date;
    salesforce_updated_by?: string;
    synced_at: Date;
    created_at: Date;
}

export interface IMeter {
    salesforce_id: string;
    name: string;

    account_salesforce_id?: string;
    account: IAccount;

    site_id?: number;
    site?: ISite;

    service_account_number?: string;
    event_max_duration?: number;
    contract_max_per_site?: number;
    store_number?: string;
    zone_sub_lap?: string;
    number_of_active_enrollments?: number;
    salesforce_url: string;

    country?: string;
    state?: string;
    city?: string;
    address?: string;
    zip?: string;
    latitude?: number;
    longitude?: number;

    enrollments?: IMeterEnrollment[];

    salesforce_created_at: string;
    salesforce_created_by: string;
    salesforce_updated_at: string;
    salesforce_updated_by: string;
    created_at: Date;
    synced_at?: Date;
}

export interface ICreateSite {
    site_id?: number; // to keep same fields as in IUpdateSite

    company_id?: number;
    control_provider_id?: number | null;
    program_ids?: number[] | null;
    google_place_id?: string | null;
    site_name: string;
    site_address: string;
    site_city: string;
    site_state: string;
    site_zip: string;
    site_county?: string | null;
    site_country: string | null;
    site_lat: number | null;
    site_long: number | null;
    site_timezone: string | null;
    site_store_number?: string | null;
    site_estimated_kw?: number;
    site_slap?: string;
    site_utility?: string;
    event_offset?: number;
    event_max_duration?: number | null;
    site_resource_id?: string;
    partner_id?: string | null;
    create_customer_ven?: boolean | null;
    site_highlight?: boolean | null;
    site_network?: string;
    site_label?: string[];
    email_only_integration?: boolean;
    lmp_market?: string | null;
    site_load_zone?: string | null;
    meter_ids?: string[];

    sans?: ISanFormItem[];
}

export interface IUpdateSite {
    site_id: number;
    company_id: number;
    control_provider_id?: number | null;
    google_place_id?: string | null;
    site_name: string;
    site_address: string;
    site_city: string;
    site_state: string;
    site_zip: string;
    site_county: string | null;
    site_country: string | null;
    site_lat: number | null;
    site_long: number | null;
    site_timezone: string | null;
    site_store_number?: string | null;
    site_estimated_kw: number;
    site_slap?: string | null;
    site_utility?: string | null;
    event_offset: number;
    event_max_duration?: number | null;
    site_resource_id?: string | null;
    partner_id?: string | null;
    site_highlight: boolean;
    site_network?: string | null;
    site_label?: string[];
    email_only_integration?: boolean;
    lmp_market?: string | null;
    site_load_zone?: string | null;

    meter_ids?: string[];
    program_ids?: number[] | null; // if null - do not change programs enrollment
    sans?: ISanFormItem[];
}

export interface ISanInfoSource {
    san: string;
    source: string;
    utility: string;
    customerName: string;
    customerAccount: string;
    serviceSupplier: {
        lse: string;
        mdma: string;
        msp: string;
    } | null;
    leapMeterId?: string;
    address: {
        address: string;
        city: string;
        state: string;
        postalCode: string | number;
    };
    tariff?: string;
    agreement?: IAgreement;
    latestIntervalDataDate?: string;
    usagePoint: string;
}

export enum IntervalDataStatus {
    CONNECTED = 'connected',
    CONNECTED_BUT_HAS_PROBLEM = 'connected_but_has_problem',
    NOT_CONNECTED = 'not-connected',
    UNKNOWN = 'unknown', // never connected or no information
}

export interface ISanInfo {
    san: string;
    latestIntervalDataDate?: string;
    sources: ISanInfoSource[];
    intervalDataState: {
        status: IntervalDataStatus;
        message: string;
    };
    updatedAt: string;
}

export interface IFetchSitePageQuery extends IFetchPageQuery {
    fields?: string;
    hasLabel?: boolean;
}

export interface IFetchLocationsQuery {
    companyId: number | null;
}

export interface IFetchSiteLabelsQuery {
    companyId: number | null;
}

export interface IFetchSiteLabelsResult {
    site_labels: string[];
}

export interface ISiteEnrollment {
    site_price_response_enrolled?: boolean;
    site_clean_response_enrolled?: boolean;
    site_leap_api_dispatch_enrolled?: boolean;
}

export enum PARTNER_ID_ERROR_STATUS {
    SUCCESS = 'success',
    SOLVED = 'solved',
    WARNING = 'warning',
    ERROR = 'error',
}

export interface ISiteAddressCheckRequest {
    companyId: number;
    site_address: string;
    site_city: string;
    site_state: string;
    site_zip: string;
    site_country: string;
    google_place_id?: string;
}

export interface ISiteStoreNumberCheckRequest {
    companyId: number;
    storeNumbers: string[];
}

export interface ISiteStoreNumberCheckResponse {
    companyId: number;
    duplicatedStoreNumbers: string[];
}

export interface IAnalyticsChartsData {
    energyData: IEnergyData[];
    eventData: IEventData[];
    energyBaselineData: [number, number][];
    energyPerformanceData: [number, number][];
}
