import { ICompany } from '../company/interface';
import { SignalType } from '../event/interface';
import { PAGE_ACTION } from '../commonConst';

export enum SPECIFIC_PRICE_RESPONSE_ACTION {
    BATCH_UPDATE = 'batch_update',
    BATCH_DELETE = 'batch_enroll_clean_response',
}

export type PRICE_RESPONSE_ACTION = SPECIFIC_PRICE_RESPONSE_ACTION | PAGE_ACTION;

export enum PriceType {
    REAL_TIME = 'real-time',
    DAY_AHEAD = 'day-ahead',
}

export interface IAffectedPriceResponseTrigger {
    id: number;
    company: string;
    market: string;
    load_zone: string;
    price_type: PriceType;
}

export interface IPriceResponseTrigger {
    id: number;
    company_id: number;
    company: ICompany;
    price: number;
    price_type: PriceType;
    market: string;
    load_zone: string;
    duration: number;
    signal: SignalType;
    date_created: string;
    start_time: null | string;
    end_time: null | string;
    cancel_when_price_lower: boolean;
}

export interface IBatchUpdatePriceResponseTrigger {
    price?: number;
    start_time?: string | null;
    end_time?: string | null;
    duration?: number;
    signal?: SignalType;
}

export const PRICE_RESPONSE_MARKETS_DATA: IPriceResponseMarket = {
    ERCOT: ['AEN', 'CPS', 'HOUSTON', 'LCRA', 'NORTH', 'RAYBN', 'SOUTH', 'WEST'],
    CAISO: [
        'SVP',
        'SCEW',
        'LADWP',
        'SCEC',
        'SCNW',
        'SCEN',
        'SDGE',
        'SCLD',
        'SCHD',
        'PGZP',
        'PGF1',
        'PGKN',
        'PGNP',
        'PGCC',
        'PGP2',
        'PGSF',
        'PGSB',
        'SMUD',
        'PGNB',
        'PGEB',
        'PGFG',
        'PGST',
        'PGNC',
        'PGHB',
        'PGSI',
    ],
    NYISO: ['WEST', 'GENESE', 'CENTRL', 'NORTH', 'MHK VL', 'CAPITL', 'HUD VL', 'MILLWD', 'DUNWOD', 'N.Y.C.', 'LONGIL'],
    PJM: [
        'AECO',
        'AEP',
        'APS',
        'ATSI',
        'BGE',
        'COMED',
        'DAY',
        'DEOK',
        'DOM',
        'DPL',
        'DUQ',
        'EKPC',
        'JCPL',
        'METED',
        'PECO',
        'PENELEC',
        'PEPCO',
        'PPL',
        'PSEG',
        'RECO',
        'OVEC',
    ],
    ISONE: ['ME', 'NH', 'VT', 'CT', 'RI', 'SE MA', 'WC MA', 'NE MA'],
};

export interface ISiteMetrics {
    date: string;
    enrolled: number;
    unenrolled: number;
}

export interface ISavingsMetrics {
    total: {
        date: string;
        value: number;
    }[];
    avgPerSite: number;
    avgPerEvent: number;
}

export interface IPriceResponseEventMetrics {
    date: string;
    count: number;
    days: number;
}

export interface IPriceResponseMetric {
    sites: {
        first: ISiteMetrics | null;
        actual: ISiteMetrics | null;
    };
    savings: ISavingsMetrics;
    events: IPriceResponseEventMetrics[];
    trigger: IPriceResponseTrigger | null;
    dateModified: string | null;
    company: ICompany;
    impact: {
        metrics: {
            [key: string]: {
                co2Impact: number;
            };
        };
        comparisons: {
            acresOfForest: number;
            homeElectricityPerYear: number;
            passengerVehicle: number;
            passengerVehicleMiles: number;
            poundsOfCoalBurned: number;
            seedlingPlanted: number;
        };
    } | null;
}

export interface IPriceResponseMarket {
    [key: string]: string[];
}

export interface IFetchPriceResponseReportingQuery {
    start: Date;
    end: Date;
    companyId: number;
}
