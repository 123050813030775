import Badge from 'antd/lib/badge';
import { NYISOResourceStatus } from '../../../domain/nyiso/interface';
import { ERROR_COLOR, INFO_COLOR, PRIMARY_COLOR } from '../../../theme';

interface IResourceStatusProps {
    status: NYISOResourceStatus;
    isEnabled: boolean;
}

export const ResourceStatus = ({status, isEnabled}: IResourceStatusProps) => {
    if (!isEnabled) return <Badge status='default' text='Disabled'/>;

    if (status === NYISOResourceStatus.ERROR) return <Badge color={ERROR_COLOR} text='Error'/>
    if (status === NYISOResourceStatus.PROCESSING) return <Badge color={INFO_COLOR} text='Processing'/>

    return <Badge color={PRIMARY_COLOR} text='Ready'/>
}