import CheckOutlined from '@ant-design/icons/CheckOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Button from 'antd/lib/button';
import App from 'antd/lib/app';
import Row from 'antd/lib/row';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { endOfMonth, startOfMonth } from 'date-fns';
import { MemoizedDatetimePopover } from '../../../components/datetimePopover/datetimePopover';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { WithEmptyDataTable } from '../../../components/table/withEmptyDataTable';
import { exportTrackingAlerts } from '../../../domain/event';
import { SearchInput } from '../../../components/searchInput/searchInput';
import { useAlertTrackListQuery } from '../../../domain/alert-track/queries';
import { ITrackingAlert } from '../../../domain/event/interface';
import { toUsefulParams } from '../../toUsefulParams';
import { usePageLocation } from '../../usePageState';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { IView } from '../../../components/viewSwitcher/viewInterface';
import { DatePeriod, DatePeriodSelector } from '../../../components/selectors/DatePeriodSelector/DatePeriodSelector';
import { IDateRange } from '../../interface';
import { eventViewOptions } from '../eventInterface';
import { dateToUtcLocale } from '../../../domain/common/timeHelpers';
import { TrackingAlertStatusIcon } from '../../../components/TrackingAlertStatusIcon';
import { SUCCESS_COLOR } from '../../../theme';

const defaultDate = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
};

const defaultState = {
    loading: false,
    date: defaultDate,
};

export const EventTrackingView = ({ company, auth, handleViewMode, view }: IView) => {
    const { notification } = App.useApp();
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = useMemo(() => queryToState(location.search), [location, queryToState]);

    const [state, setState] = useState({ ...defaultState, ...pageState });
    const [exportLoading, setExportLoading] = useState(false);

    const listQuery = {
        companyId: company,
        search: state.search,
        start: state.date?.start ? dateToUtcLocale(new Date(state.date.start)) : undefined,
        end: state.date?.end ? dateToUtcLocale(new Date(state.date.end)) : undefined,
    }

    const { isLoading, isError, error, data: trackingAlerts } = useAlertTrackListQuery(listQuery);

    if (isError) {
        notification.error({
            key: 'alert-track-list-error',
            message: error.message || 'Cannot load alert tracks!',
        });
    }

    const columns: ColumnsType<ITrackingAlert> = [
        { title: 'Company', dataIndex: 'company' },
        { title: 'User', dataIndex: ['user', 'fname'], render: (text, record) => `${text} ${record.user?.lname}` },
        { title: 'Email', dataIndex: ['user', 'email'] },
        {
            title: 'Event',
            dataIndex: ['event', 'start'],
            render: (startDate: string) => startDate ? <MemoizedDatetimePopover
                timestamp={new Date(startDate).valueOf()} /> : '-',
        },
        { title: 'Alert Event Status', dataIndex: ['event', 'status'] },
        {
            title: 'Sent',
            dataIndex: 'sentAt',
            render: (sendDate: string) => sendDate ? <MemoizedDatetimePopover
                timestamp={new Date(sendDate).valueOf()} /> : '-',
        },
        {
            title: 'Opened',
            dataIndex: 'openedAt',
            align: 'center',
            width: 90,
            render: text => text && <CheckOutlined style={{ color: SUCCESS_COLOR }} />,
        },
        {
            title: 'Confirmed',
            dataIndex: 'confirmedAt',
            align: 'center',
            width: 90,
            render: (text, record) => <TrackingAlertStatusIcon trackingAlert={record} />,
        },
    ];

    const exportTrackingAlertsAction = async () => {
        setExportLoading(true);
        try {
            await exportTrackingAlerts({
                companyId: company,
                start: state.date?.start ? dateToUtcLocale(new Date(state.date.start)) : undefined,
                end: state.date?.end ? dateToUtcLocale(new Date(state.date.end)) : undefined,
            });
        } catch (error: any) {
            notification.error({
                key: 'events-tracking-alerts-export-error',
                message: error.message || 'Cannot export tracking alerts!',
            });
        }
        setExportLoading(false);
    };

    const onTableChange = (pagination: any) => {
        const preparedParamsForQuery = toUsefulParams({ pagination });
        setState({ ...state, pagination });
        setPageQuery({ ...pageState, ...preparedParamsForQuery });
    };

    const handleSearch = (value: string) => {
        setState({ ...state, search: value });
        setPageQuery({ ...pageState, search: value });
    };

    const handleDatePeriodChange = (selectedDate: IDateRange) => {
        setState({ ...state, date: selectedDate });
        setPageQuery({ ...pageState, date: selectedDate });
    };

    return (
        <>
            <PageHeader
                pageTitle='Events'
                extra={[
                    <ViewSwitcher
                        key='event-view-switcher'
                        viewOptions={eventViewOptions(auth?.user?.isAdminRoleType()!)}
                        view={view}
                        handleViewMode={handleViewMode}
                    />,
                ]}
                actions={[
                    <SearchInput key='alert-event-search' onSearch={handleSearch} defaultValue={state?.search} />,
                    <DatePeriodSelector
                        key='date-period-selector'
                        onChange={handleDatePeriodChange}
                        selectedPeriod={state.date}
                        disabled={isLoading}
                        optionsType={[DatePeriod.MONTHLY, DatePeriod.QUARTERLY]}
                    />,
                    <Button loading={exportLoading} size='large' key='download-csv' onClick={exportTrackingAlertsAction}
                            icon={<DownloadOutlined />}>
                        Download CSV
                    </Button>,
                ]}
            />
            <Row>
                <WithEmptyDataTable condition={isLoading} emptyDataDescription='No Data for Specified Search'>
                    <Table
                        size='small'
                        style={{ width: '100%' }}
                        sticky
                        columns={columns}
                        dataSource={trackingAlerts}
                        loading={isLoading}
                        pagination={{
                            ...state.pagination,
                            showSizeChanger: true,
                            size: 'default',
                            defaultPageSize: 100,
                        }}
                        onChange={onTableChange}
                    />
                </WithEmptyDataTable>
            </Row>
        </>
    );
};
