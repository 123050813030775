import CheckOutlined from '@ant-design/icons/CheckOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import Popover from 'antd/lib/popover';
import { ISanInfo, IntervalDataStatus } from '../../domain/site/interface';
import './IntervalDataTag.css';

export interface IIntervalDataTagProps {
    sanInfo?: ISanInfo | null;
}

export const IntervalDataTag = ({ sanInfo }: IIntervalDataTagProps) => {

    const SuccessIcon = <CheckOutlined style={{ color: '#2fadab', fontSize: '24px' }} />
    const WarningIcon = <WarningOutlined style={{ color: '#fa962a', fontSize: '24px' }} />

    const status = sanInfo?.intervalDataState?.status || IntervalDataStatus.UNKNOWN;
    const message = sanInfo?.intervalDataState?.message || '';

    switch (status) {
        case IntervalDataStatus.CONNECTED:
            return (
                <Popover content={message} style={{ width: '300px' }}>
                    {SuccessIcon}
                </Popover>
            )
        case IntervalDataStatus.CONNECTED_BUT_HAS_PROBLEM:
        case IntervalDataStatus.NOT_CONNECTED:
            return (
                <Popover content={message} style={{ width: '300px' }}>
                    {WarningIcon}
                </Popover>
            )
        case IntervalDataStatus.UNKNOWN:
        default:
            return <></>;
    }

};
