import { useEffect, useState } from 'react';

import Modal from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import Timeline from 'antd/lib/timeline';
import Skeleton from 'antd/lib/skeleton';
import Empty from 'antd/lib/empty';

import { fetchAuditTrail } from '../../../domain/audit-trail';
import { AuditEntityType, IAuditTrail } from '../../../domain/audit-trail/interface';
import useAbortableFetch from '../../../domain/useAbortableFetch';
import { MemoizedDatetimePopover } from '../../datetimePopover/datetimePopover';
import { orderBy } from 'lodash';
import App from 'antd/lib/app';

interface IAuditTrailModalProps {
    onClose: Function;
    entityId: number | string;
    entityType: AuditEntityType;
}

export const AuditTrailModal = ({ onClose, entityId, entityType }: IAuditTrailModalProps) => {
    const { notification } = App.useApp();
    const [abortableFetchAuditTrail] = useAbortableFetch(fetchAuditTrail);
    const [loading, setLoading] = useState<boolean>(false);
    const [auditTrailData, setAuditTrailData] = useState<IAuditTrail[]>([]);

    const fetchAuditTrailAction = async () => {
        setLoading(true);
        try {
            const { data } = await abortableFetchAuditTrail({ entityId, entityType });
            const sortedData = orderBy(data, ['datetime', 'id'], ['desc', 'desc']);
            setAuditTrailData(sortedData);
        } catch (error: any) {
            if (error.name === 'AbortError') return;
            notification.error({ key: 'audit-trail-fetch-error', message: error.message || 'Cannot fetch Audit rail Data!' });
        }
        setLoading(false);
    };

    useEffect(() => {
        (async () => fetchAuditTrailAction())();
    }, []);

    const handleClose = () => onClose();

    const renderContent = () =>
        auditTrailData.length ? (
            <Timeline>
                {auditTrailData.map(auditItem => (
                    <Timeline.Item>
                        <Typography style={{ width: 'fit-content' }}>
                            <MemoizedDatetimePopover timestamp={new Date(auditItem.datetime).valueOf()} />
                        </Typography>

                        <Typography.Text>
                            {auditItem.actor}, {auditItem.action}
                        </Typography.Text>
                        {auditItem.data && (
                            <Typography.Paragraph code style={{ whiteSpace: 'break-spaces' }} ellipsis>
                                {JSON.stringify(auditItem.data, null, 2)}
                            </Typography.Paragraph>
                        )}
                    </Timeline.Item>
                ))}
            </Timeline>
        ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        );

    return (
        <Modal
            open
            destroyOnClose
            title="Audit Trail"
            onCancel={handleClose}
            styles={{
                body: { maxHeight: '600px', overflow: 'auto' },
            }}
            footer={null}
        >
            {loading ? <Skeleton loading active /> : renderContent()}
        </Modal>
    );
};
