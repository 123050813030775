import { UploadFile } from 'antd/lib';

export const NYISO_DATE_FORMAT = 'MMM dd, yyyy';
export const NYISO_PEAK_HOURS_TIME_FORMAT = `${NYISO_DATE_FORMAT} H`;

export interface INyisoResource {
    id: number;
    resource_id: string;
    resource_name: string;
    enrollment_date?: string;
    to_service_voltage_id: string;
    aggregation_id: string;
    to_account_number: string;
    meter_authority: string;
    transmission_owner: string;
    street: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    zone: string;
    cbl_method: string;
    response_type: string;
    provisional_acl: boolean;
    enrollment_id: string;
    energy_data_files: INYISOResourceFile[];
    seasonal_energy_data: string;
    status: NYISOResourceStatus;
    is_enabled: boolean;
    date_created: string;
    date_modified: string;
}

export interface ICreateNyisoResource {
    resource_id: string;
    resource_name: string;
    enrollment_date?: string | null;
    to_service_voltage_id: string;
    aggregation_id: string;
    to_account_number: string;
    meter_authority: string;
    transmission_owner: string;
    street: string;
    street2?: string | null;
    city: string;
    state: string;
    zip: string;
    zone: string;
    cbl_method: string;
    response_type: string;
    provisional_acl: boolean;
    enrollment_id: string;
    file?: UploadFile;
}

export interface IUpdateNyisoResource {
    id: number;
    resource_id: string;
    resource_name: string;
    enrollment_date?: string | null;
    to_service_voltage_id: string;
    aggregation_id: string;
    to_account_number: string;
    meter_authority: string;
    transmission_owner: string;
    street: string;
    street2?: string | null;
    city: string;
    state: string;
    zip: string;
    zone: string;
    cbl_method: string;
    response_type: string;
    provisional_acl: boolean;
    enrollment_id: string;
    file?: UploadFile;
}

export interface INYISOResourceFile {
    file_path: string;
    originalname: string;
    date_created: string;
}

export enum NYISOResourceStatus {
    PROCESSING = 'processing',
    SUCCESS = 'success',
    ERROR = 'error',
}

export type NyisoResourceUniqueFields = 'resource_id' | 'resource_name' | 'enrollment_id';

export interface INyisoPeakHour {
    zone: string;
    top_40_hour: number;
    datetime: string;
}

export interface INyisoSeason {
    id: string;
    peak_hours: INyisoPeakHour[];
    date_created: string;
    date_modified: string;
}
